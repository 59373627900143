export interface BeneficiaryType {
  id: string;
  beneficiary_name: string;
  beneficiary_account_type: string;
  beneficiary_address: string;
  beneficiary_city: string;
  beneficiary_country_code: string;
  beneficiary_email: string;
  beneficiary_postcode: string;
  beneficiary_state: string;
  beneficiary_contact_country_code: string;
  beneficiary_contact_number: string;
  destination_currency: string;
  destination_country: string;
  beneficiary_account_number: string;
  beneficiary_alias: string;
  beneficiary_bank_name: string;
  payout_method: string;
  routing_code_type_1: string;
  routing_code_value_1: string;
  routing_code_type_2: string;
  routing_code_value_2: string;
  created_at: string;
  updated_at: string;
  beneficiary_bank_account_type?: string;
}

export enum AccountType {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
}

export interface ListField {
  label: string;
  key: keyof BeneficiaryType;
  map?: boolean;
}

export enum ActionTypes {
  EDIT = 'edit',
  DELETE = 'delete',
  SEND_MONEY = 'send-money',
}

export interface BeneficiaryPayoutEligibilty {
  id: string;
  is_beneficiary_payout_eligible: boolean;
  beneficiary_name: string;
  beneficiary_account_type: string;
  beneficiary_address: string;
  beneficiary_city: string;
  beneficiary_country_code: string;
  beneficiary_email: string;
  beneficiary_postcode: string;
  beneficiary_state: string;
  beneficiary_bank_name: string;
  beneficiary_account_number: string;
  payout_method: string;
  status: string;
  beneficiary_tax_reference_number: string;
  beneficiary_date_of_incorporation_or_birth: string;
  beneficiary_document_type: string;
  beneficiary_document_id: string;
  beneficiary_bank_type: string;
  created_at: string;
  updated_at: string;
}
