import React, { FC, memo } from 'react';
import { IMAGE_PLACEHOLDER } from 'constants/icons';
import { useAppSelector } from 'hooks/toolkit';
import Image from 'next/image';
import { RootState } from 'store';

type WalletProps = {
  wallet: string | undefined;
  icon?: string;
  className?: string;
  showLabel?: boolean;
  hideWalletName?: boolean;
  iconDimensions?: { width: number; height: number };
  iconClassName?: string;
  hideIcon?: boolean;
};

const Wallet: FC<WalletProps> = ({
  wallet = '',
  icon = '',
  className = '',
  showLabel = false,
  hideWalletName = false,
  iconDimensions,
  iconClassName,
  hideIcon = false,
}) => {
  const { networkCodeAndNameMap, currencyCodeAndIconMap, networkCodeAndIconMap } = useAppSelector(
    (state: RootState) => state.config
  );

  return (
    <div className={`tw-flex tw-items-center ${className}`}>
      {!hideIcon && (
        <Image
          src={currencyCodeAndIconMap[icon] || networkCodeAndIconMap[wallet] || IMAGE_PLACEHOLDER}
          alt='Coin'
          className={iconClassName ?? 'tw-mr-2'}
          width={iconDimensions?.width ?? 24}
          height={iconDimensions?.height ?? 24}
        />
      )}
      {!hideWalletName && <div className='tw-truncate'>{showLabel ? wallet : networkCodeAndNameMap[wallet]}</div>}
    </div>
  );
};

export default memo(Wallet);
