import { OptionsType } from 'destiny/dist/types/dropdown';
import {
  Entity,
  POLICY_ACTION_VALUES,
  POLICY_DESTINATION_SCOPE,
  POLICY_DESTINATION_TYPES,
  POLICY_DETAILS_FIELDS,
  POLICY_SOURCE_SCOPE,
  PolicyDestination,
  PolicyDestinationPayload,
  PolicyDetailsStateType,
  PolicyInitiator,
  PolicySource,
} from 'modules/policies/policies.types';
import { store } from 'store';
import { BeneficiaryType } from 'types/beneficiary';
import { sentenceCase } from 'utils/common';

export const formatSourceAccountData = (data: Record<string, Entity>) => {
  if (!data) return [];
  const formattedData: PolicySource[] = [];

  const entities = Object.values(data);

  entities?.forEach((entity) => {
    const accounts = entity?.accounts || [];

    accounts?.forEach((account) => {
      formattedData.push({
        type: 'account',
        id: account.id,
        contextual_data: {
          name: account.display_name,
          masked_number: account.masked_number,
        },
      });
    });
  });

  return formattedData;
};

export const formatDestinationRecipientData = (data: BeneficiaryType[]) => {
  const formattedData: PolicyDestination[] = [];

  data?.forEach((beneficiary) => {
    formattedData.push({
      type: 'beneficiary',
      id: beneficiary.id,
      contextual_data: {
        name: beneficiary.beneficiary_name,
        account_number: beneficiary.beneficiary_account_number,
        destination_currency: beneficiary.destination_currency,
      },
    });
  });

  return formattedData;
};

export const formatInitiatorRolesData: (
  data: OptionsType[],
  useMetadata?: boolean,
  useName?: boolean
) => PolicyInitiator[] = (data = [], useMetadata = false, useName = false) => {
  const formattedData: PolicyInitiator[] = [];

  data?.forEach((role) => {
    formattedData.push({
      type: 'role',
      id: `${role.value}`,
      name: useMetadata
        ? `${role?.metadata?.name} (${role?.metadata?.users?.length ?? 0})`
        : useName
        ? role?.metadata?.name
        : (role.label as string),
    });
  });

  return formattedData;
};

export const formSuffix = (scope: string, length = 0) => {
  switch (scope) {
    case 'entity':
      return `${length > 1 ? 'Entities' : 'Entity'}`;
    case 'beneficiary':
      return `${length > 1 ? 'Recipients' : 'Recipient'}`;
    case POLICY_DESTINATION_TYPES.ANY:
      return '';
    default:
      return `${sentenceCase(scope)}${length > 1 ? 's' : ''}`;
  }
};

export const isBlockSelected = (data: PolicyDetailsStateType) =>
  data[POLICY_DETAILS_FIELDS.POLICY_ACTION]?.value === POLICY_ACTION_VALUES.BLOCK;

export const getAccountIds = (data: Record<string, Entity>) => {
  const entities = data ? Object.values(data) : [];
  const accountIds: string[] = [];

  entities?.forEach((entity) => {
    const accounts = entity?.accounts || [];

    accounts?.forEach((account) => {
      accountIds.push(account.id);
    });
  });

  return accountIds;
};

export const formatDestinationRecipientRequestPayload = (data: BeneficiaryType[], isSelectAllSelected: boolean) => {
  const formattedData: PolicyDestinationPayload[] = [];
  const { user } = store.getState().user;

  isSelectAllSelected
    ? formattedData.push({
        type: 'beneficiary',
        scope_id: user?.merchant_id,
        scope: POLICY_DESTINATION_SCOPE.MERCHANT,
      })
    : data?.forEach((beneficiary) => {
        formattedData.push({
          type: 'beneficiary',
          scope_id: beneficiary.id,
          scope: POLICY_DESTINATION_SCOPE.SELF,
        });
      });

  return formattedData;
};

export const formatSourceAccountRequestPayload = (data: Record<string, Entity>, isSelectAllSelected: boolean) => {
  const formattedData: PolicySource[] = [];
  const { user } = store.getState().user;

  if (isSelectAllSelected) {
    formattedData.push({
      type: 'account',
      scope_id: user?.merchant_id,
      scope: POLICY_SOURCE_SCOPE.MERCHANT,
    });
  } else {
    const entities = data ? Object.values(data) : [];

    entities?.forEach((entity) => {
      const accounts = entity?.accounts || [];

      accounts?.forEach((account) => {
        formattedData.push({
          type: 'account',
          scope_id: account.id,
          scope: POLICY_SOURCE_SCOPE.SELF,
          contextual_data: {
            name: account.display_name,
            masked_number: account.masked_number,
          },
        });
      });
    });
  }

  return formattedData;
};

export const getAccountIdsLength = (selectedData: Record<string, Entity>) => {
  const accountIds: string[] = [];

  if (Array.isArray(selectedData)) return selectedData.length;
  else {
    const entities = Object.values(selectedData);

    entities.forEach((entity) => {
      const accounts = entity?.accounts || [];

      accounts.forEach((account) => {
        accountIds.push(account.id);
      });
    });

    return accountIds.length;
  }
};
