import { BeneficiaryType } from 'types/beneficiary';

export enum POLICY_CREATION_STEPS {
  POLICY_DETAILS,
  APPROVERS_SELECTION,
  REVIEW,
}

export enum POLICY_CONFIGURATION_TYPES {
  SOURCE,
  DESTINATION,
  APPROVAL_STEPS,
  INITIATOR,
}

export enum POLICY_DETAILS_FIELDS {
  POLICY_NAME = 'policy_name',
  POLICY_AMOUNT = 'policy_amount',
  POLICY_DURATION = 'policy_duration',
  POLICY_ACTION = 'policy_action',
  POLICY_INITIATOR = 'initiators',
  POLICY_SOURCES = 'sources',
  POLICY_DESTINATIONS = 'destinations',
  POLICY_DESTINATION_SELECTOR = 'policy_destination_selector',
  POLICY_SOURCE_SELECTOR = 'policy_source_selector',
}

export interface Account {
  id: string;
  display_name: string;
  masked_number: string;
  entityId?: string;
  entityName?: string;
}
export interface Entity {
  id: string;
  display_name: string;
  accounts: Account[];
}

export interface SelectorPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface PolicyAccountSelectorProps extends SelectorPopupProps {
  selectedData?: Record<string, Entity>;
  onSave: (selectedData: Record<string, Entity>) => void;
  roleIds?: string[];
}

export interface SelectedAccountsProps {
  data: Entity[];
  onDelete: (account: Account) => void;
}

export interface PolicyRecipientSelectorProps extends SelectorPopupProps {
  selectedData?: BeneficiaryType[];
  onSave: (selectedData: BeneficiaryType[]) => void;
}

export interface SelectedRecipientsProps {
  data: BeneficiaryType[];
  onDelete: (value: { value: string; id: string; metadata?: BeneficiaryType }) => void;
}

export enum POLICY_QUORUM {
  ONE = 'ONE',
  ALL = 'ALL',
}

export enum POLICY_SEQUENCE_OPERATORS {
  OR = 'OR',
  AND = 'AND',
}

export enum APPROVER_TYPE {
  ROLE = 'role',
  B2B_USER = 'b2b_user',
}

export enum ApproverSelectionKeys {
  APPROVERS = 'approvers',
  QUORUM = 'quorum',
  NEXT_OPERATOR = 'nextOperator',
  ERROR = 'error',
}

export interface ApproverSelectionType {
  [ApproverSelectionKeys.APPROVERS]: { id: string; display_name: string; type: APPROVER_TYPE }[];
  [ApproverSelectionKeys.QUORUM]: { value: POLICY_QUORUM; label?: string };
  [ApproverSelectionKeys.NEXT_OPERATOR]: POLICY_SEQUENCE_OPERATORS | null;
  [ApproverSelectionKeys.ERROR]?: string;
}

export interface PolicySource {
  type: string;
  id?: string;
  scope_id?: string;
  scope?: string;
  contextual_data?: {
    name: string;
    nick_name?: string;
    currency_code?: string;
    masked_number: string;
  };
}

export interface PolicyDestination {
  type: string;
  id: string;
  contextual_data?: {
    name: string;
    account_number: string;
    destination_currency: string;
  };
}

export interface PolicyInitiator {
  type: string;
  id: string;
  name: string;
}

export enum POLICY_ACTION_VALUES {
  SEND_FOR_APPROVAL = 'request_for_approval',
  BLOCK = 'block',
}

export type PolicyDetailsStateType = {
  [POLICY_DETAILS_FIELDS.POLICY_NAME]: string;
  [POLICY_DETAILS_FIELDS.POLICY_AMOUNT]: string;
  [POLICY_DETAILS_FIELDS.POLICY_DURATION]: any;
  [POLICY_DETAILS_FIELDS.POLICY_ACTION]: any;
  [POLICY_DETAILS_FIELDS.POLICY_INITIATOR]: any;
  [POLICY_DETAILS_FIELDS.POLICY_SOURCES]: any;
  [POLICY_DETAILS_FIELDS.POLICY_DESTINATIONS]: any;
  [POLICY_DETAILS_FIELDS.POLICY_DESTINATION_SELECTOR]: any;
  [POLICY_DETAILS_FIELDS.POLICY_SOURCE_SELECTOR]: any;
};

export interface PolicyDestinationPayload {
  type: string;
  scope_id: string;
  scope: string;
}

export enum POLICY_DESTINATION_SCOPE {
  MERCHANT = 'merchant',
  SELF = 'self',
}

export enum POLICY_DESTINATION_TYPES {
  ANY = 'any',
  SELECT = 'select',
}

export interface PolicyDetailsStepItemProps {
  state: any;
  onChange: (name: POLICY_DETAILS_FIELDS, value: any) => void;
  disabled?: boolean;
}

export enum POLICY_SOURCE_TYPES {
  ANY = 'any',
  SELECT = 'select',
}

export enum POLICY_SOURCE_SCOPE {
  MERCHANT = 'merchant',
  SELF = 'self',
}
