import { FLOW_PERMISSION_PROPERTIES } from 'constants/accessConstants';
import { ACCESS_HEADER_RESOURCE_KEY } from 'constants/config';
import { APITags, REQUEST_TYPES } from 'constants/index';
import baseApi from 'services/api';
import { MapAny } from 'types';
import { BeneficiaryPayoutEligibilty, BeneficiaryType } from 'types/beneficiary';
import { Map } from 'types/kyb';
import { accessBasedQueryWrapper } from 'utils/access';

const Beneficiary = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getBeneficiaries: builder.query<Array<BeneficiaryType>, Map>({
      query: ({ search, type }) =>
        accessBasedQueryWrapper(null, {
          url: 'b2b-transfer/v1/beneficiaries',
          params: {
            ...(search && { search }),
            ...(type?.length && { type }),
          },
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      providesTags: [APITags.GET_BENEFICIARY],
      transformResponse: ({ data }) => data,
    }),

    getAddBeneficiaryFormConfig: builder.query<Map, Map>({
      query: ({ isEdit, params }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: `v1/form/beneficiary/${isEdit ? 'update' : 'create'}`,
          params,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),

    getAddRecipientFormConfig: builder.query<Map, void>({
      query: () =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: 'v2/form/beneficiary/create',
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),

    getBeneficiariesById: builder.query<BeneficiaryType, Map>({
      query: ({ id }) =>
        accessBasedQueryWrapper(null, {
          url: `b2b-transfer/v1/beneficiaries/${id}`,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      transformResponse: ({ data }) => data,
    }),

    createBeneficiary: builder.mutation<Map, Map>({
      query: (payload) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: `b2b-transfer/v1/beneficiary`,
          method: REQUEST_TYPES.POST,
          body: payload,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      invalidatesTags: [APITags.GET_BENEFICIARY],
      transformResponse: ({ data }) => data,
    }),

    deleteBeneficiary: builder.mutation<Map, BeneficiaryType>({
      query: ({ id }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: `b2b-transfer/v1/beneficiaries/${id}`,
          method: REQUEST_TYPES.DELETE,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      invalidatesTags: [APITags.GET_BENEFICIARY],
    }),

    updateBeneficiary: builder.mutation<Map, Map>({
      query: ({ payload, id }) =>
        accessBasedQueryWrapper(FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.permissionId, {
          url: `b2b-transfer/v1/beneficiaries/${id}`,
          method: REQUEST_TYPES.PATCH,
          body: payload,
          headers: {
            [ACCESS_HEADER_RESOURCE_KEY]: FLOW_PERMISSION_PROPERTIES.MANAGE_RECEPIENTS.scope,
          },
        }),
      invalidatesTags: [APITags.GET_BENEFICIARY, APITags.GET_COUNTER_PARTY_BY_ID],
      transformResponse: ({ data }) => data,
    }),

    getBeneficiaryPayoutEligibility: builder.query<BeneficiaryPayoutEligibilty, string>({
      query: (quote_id) => ({
        url: `b2b-transfer/v1/beneficiary/payout/eligibility/${quote_id}`,
      }),
      transformResponse: ({ data }) => data,
    }),

    getUpdateBeneficiaryForm: builder.query<MapAny, string>({
      query: (bene_id) => ({
        url: `/v2/form/beneficiary/update?beneficiaryId=${bene_id}`,
      }),
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const {
  useLazyGetBeneficiariesQuery,
  useGetBeneficiariesByIdQuery,
  useLazyGetAddBeneficiaryFormConfigQuery,
  useGetAddRecipientFormConfigQuery,
  useCreateBeneficiaryMutation,
  useDeleteBeneficiaryMutation,
  useUpdateBeneficiaryMutation,
  useLazyGetBeneficiariesByIdQuery,
  useLazyGetBeneficiaryPayoutEligibilityQuery,
  useGetUpdateBeneficiaryFormQuery,
} = Beneficiary;
